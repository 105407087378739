export const releaseInfo =
 {
 	branch: "Production",
 	lastCommit: "10/21/2024 15:10:52",
 	lastTag: "",
 	buildType: "local",
 	company: "Pinnacle Power Services",
 	user: "dmorr",
 	compileTime: "11/12/2024 16:34:30"
 }
