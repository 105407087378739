import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdbService } from './idb.service';
import { Command } from 'app/models/command';
import { ToolboxService } from 'app/toolbox.service';
import { SiteReviewService } from './site-review.service';
import { map } from 'rxjs/operators';
import { RowComp } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { DataStoreService } from './datastore.service';
import { ChatterService } from 'app/chat/shared/services/chatter.service';

@Injectable({
  providedIn: 'root'
})
export class BulkLoaderService {

  constructor(private idb: IdbService, private tb: ToolboxService, private db: SiteReviewService, private dataStore: DataStoreService, private chatter: ChatterService,) { }

  jobListTrigger = new BehaviorSubject(0)
  percentage = new BehaviorSubject(0);
  private _packageCount;
  private _countDown;
  private _percentage: number = 0;
  existingIds: Set<number> = new Set([]); 
  loadedJobRecords = [];
  public loader = new BehaviorSubject<number>(0);

  
  // comment this function to avoid confusion, this is the old bulkLoader

  // async bulkLoader_old(gridApi) {
  //   let commands = [];
  //   await this.idb.clearData('jobs')
  //   if (gridApi) gridApi.setRowData([]);
  //   for (var y = 2019; y <= new Date().getFullYear(); y++) {
  //     for (var m = 0; m <= 11; m++) {
  //       var d = new Date(y, m, 1);
  //       if (d <= new Date()) {
  //         console.log('date', d)
  //         var endDate;
  //         if (m == 11) {
  //           endDate = new Date(y + 1, 0, 1);
  //         } else {
  //           endDate = new Date(y, m + 1, 1);
  //         }
  //         endDate.setSeconds(endDate.getSeconds() - 1);
  //         commands.push({ startDate: d, endDate: endDate })
  //       }
  //     }
  //   }
  //   this._packageCount = commands.length;
  //   this._countDown = commands.length;
  //   commands = commands.reverse();
  //   commands.forEach(cmd => {
  //     this.getChunk(cmd.startDate, cmd.endDate, gridApi)
  //   });
  // }

  get loader$() {
    return this.loader.asObservable(); 
  }

  async jsonBulkLoader(gridApi, gridOptions) {
    gridApi?.setRowData([] as any);
    this.loader.next(0);
    let progress = 0;

    const intervalId = setInterval(() => {
      progress += 20;
      this.loader.next(progress); 
      if (progress >= 101) {
        clearInterval(intervalId); 
      }
    }, 800);
    await this.idb.clearData('jobs');
    this.db.getJobsGoogleJson().subscribe({
      next: async (res) => {
        //console.log("getJobsGoogleJson ", res);
        let jsonFile: any = res;
        gridOptions.localeText.noRowsToShow = 'Loading complete...';
        if (Array.isArray(jsonFile) && jsonFile.length > 0) {
          // make sure to eliminate duplicates
          this.existingIds.clear();
          let filteredJobs = jsonFile.filter((newRow: any) => !this.existingIds.has(newRow.jobID));
          
          filteredJobs.forEach((job: any) => {
            this.existingIds.add(job.jobID);
            this.formatBulkLoaderJobs(job);
          });
          if (filteredJobs.length > 0) {
            filteredJobs = await this.clearRows(gridApi, filteredJobs);
            //gridApi?.setRowData(filteredJobs as any);
            await gridApi.applyTransactionAsync({ add: filteredJobs }, this.callback);
            // then we update the local DB
            this.idb.insertBulk('jobs', filteredJobs, async () => {
              //console.log('done inserting ' + filteredJobs.length + ' rows');
            });
       //     this.updateLoader(gridApi);
            this.updateLoader(gridApi,true);
          }
        } else {
          console.warn("Received empty or invalid JSON data");
          clearInterval(intervalId);
          this.loader.next(100); 
          gridOptions.localeText.noRowsToShow = 'No Rows to Show';
        }
      },
      error: (error) => {
        console.error("Error in getJobsGoogleJson:", error);
        clearInterval(intervalId);
        this.loader.next(100); 
      }
    });
  }

  formatBulkLoaderJobs(job) {

    if (`${job.pmNumber}` != '') { job.pMNumber = job.pmNumber; }

    if (`${job.waApprovalDate}` != '') { job.wAApprovalDate = job.waApprovalDate; }
    if (`${job.waCurrent}` != '') { job.wACurrent = job.waCurrent; }
    if (`${job.waCurrentStatus}` != '') { job.wACurrentStatus = job.waCurrentStatus; }
    if (`${job.waDelta}` != '') { job.wADelta = job.waDelta; }

    if (`${job.waSubmissionDate}` != '') { job.wASubmissionDate = job.waSubmissionDate; }
    if (`${job.waApprovalDate}` != '') { job.wAApprovalDate = job.waApprovalDate; }
    if (`${job.waDescription}` != '') { job.wADescription = job.waDescription; }
    if (`${job.waTotal}` != '') { job.wATotal = job.waTotal; }
    if (`${job.pgeFieldEngineer}` != '') { job.pGEFieldEngineer = job.pgeFieldEngineer; }
    if (`${job.poWRO}` != '') { job.pOWRO = job.poWRO; }
    if (`${job.waPricingStructure}` != '') { job.wAPricingStructure = job.waPricingStructure; }
    if (`${job.pcNotes}` != '') { job.pCNotes = job.pcNotes; }

    if (`${job.usaDone}` != '') { job.uSADone = job.usaDone; }







    if (`${job.pgeFieldEngineer}` != '') { job.pGEFieldEngineer = job.pgeFieldEngineer; }
    if (job.waPricingStructure != '') { job.wAPricingStructure = job.waPricingStructure; }
    if (job.qaFailCount != '') { job.qAFailCount = job.qaFailCount; }
    if (job.qaInitialScore != '') { job.qAInitialScore = job.qaInitialScore; }
    if (job.qaReceived != '') { job.qAReceived = job.qaReceived; }
    if (job.qaInitialReceivedDate != '') { job.qAInitialReceivedDate = job.qaInitialReceivedDate; }
    if (job.qaScore != '') { job.qAScore = job.qaScore; }
    if (job.qaStatus != '') { job.qAStatus = job.qaStatus; }
    if (job.qaSubmitted != '') { job.qASubmitted = job.qaSubmitted; }
    if (job.qaReviewType != '') { job.qAReviewType = job.qaReviewType; }
    if (job.qaPassFail != '') { job.qAReviewType = job.qaPassFail; }
    if (job.waCurrent != '') { job.wACurrent = job.waCurrent; }
    if (job.copReceivedDate != '') { job.cOPReceivedDate = job.copReceivedDate; }
    if (job.copSubmittedDate != '') { job.cOPSubmittedDate = job.copSubmittedDate; }
    if (job.w1S != '') { job.w1s = job.w1S; }
    if (job.w2S != '') { job.w2s = job.w2S; }
    if (job.w3S != '') { job.w3s = job.w3S; }
    if (`${job.lmeComplete}` != '') { job.lMEComplete = job.lmeComplete; }
    if (job.updatedDate === null || job.updatedDate === undefined || job.updatedDate === '') {
      job.updatedDate = new Date(job.jobCreatedDate);
    } else {
      job.updatedDate = new Date(job.updatedDate);
    }
  }

  async bulkLoader(gridApi) {
    let commands = [];
    await this.idb.clearData('jobs');
    this.existingIds = new Set([]);

    gridApi.setRowData([]);

    for (let y = 2019; y <= new Date().getFullYear(); y++) {
      if (y > 2022) {
        // Generate weekly intervals for the current year
        let currentDate = new Date(y, 0, 1);
        while (currentDate <= new Date()) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          endDate.setDate(endDate.getDate() + 7);
          if (endDate > new Date()) {
            endDate.setTime(new Date().getTime()); // Set to current date
          }
          endDate = this.setToEndOfDay(endDate);
          commands.push({ startDate, endDate });
          currentDate.setDate(currentDate.getDate() + 7);
        }
      } else {
        // Generate monthly intervals for previous years
        for (let m = 0; m <= 11; m++) {
          const d = new Date(y, m, 1);
          if (d <= new Date()) {
            console.log('date', d);
            let endDate;
            if (m === 11) {
              endDate = new Date(y + 1, 0, 1);
            } else {
              endDate = new Date(y, m + 1, 1);
            }
            // Set start date to 12:00 AM (midnight)
            const startDate = new Date(d);
            startDate.setHours(0, 0, 0, 0);
            // Set end date to the last minute of the month
            endDate.setHours(23, 59, 59, 999);
            commands.push({ startDate, endDate });
          }
        }
      }
    }

    // Now your `commands` array contains precise datetime intervals
    console.log(commands);
    this._packageCount = commands.length;
    this._countDown = commands.length;
    commands = commands.reverse();
    commands.forEach(cmd => {
      return this.getChunk(cmd.startDate, cmd.endDate, gridApi);
    });


    
  }


  setToEndOfDay(dateTimeString: Date): Date | null {
    try {
      // Parse the input date string
      const parsedDate = new Date(dateTimeString);

      // Check if parsing was successful
      if (isNaN(parsedDate.getTime())) {
        console.error('Invalid date format. Please provide a valid date and time.');
        return null;
      }

      // Set the time to 11:59 PM
      parsedDate.setHours(23, 59, 0, 0);
      return parsedDate;
    } catch (error) {
      console.error('Error occurred while processing the date:', error);
      return null;
    }
  }

  private callback(e) {
    //console.log('callback reload', e)
  }

  private getChunk(b, e, gridApi) {
    this.db.getChunk(b, e)
      .pipe(
        map((retval: any) => {
          retval.forEach(row => {
            try{
              if(!row.pMNumber && row.pmNumber ){
                row.pMNumber = row.pmNumber
              }



              if(row.pmNumber !=''){row.pMNumber = row.pmNumber}
              if(row.waPricingStructure !=''){  row.wAPricingStructure = row.waPricingStructure; }
              if(row.qaFailCount !=''){  row.qAFailCount = row.qaFailCount; }
              if(row.qaInitialScore !=''){  row.qAInitialScore = row.qaInitialScore; }
              if(row.qaReceived !=''){  row.qAReceived = row.qaReceived; }
              if(row.qaInitialReceivedDate !=''){  row.qAInitialReceivedDate = row.qaInitialReceivedDate; }
              if(row.qaScore !=''){  row.qAScore = row.qaScore; }
              if(row.qaStatus !=''){  row.qAStatus = row.qaStatus; }
              if(row.qaSubmitted !=''){  row.qASubmitted = row.qaSubmitted; }
              if(row.qaReviewType !=''){  row.qAReviewType = row.qaReviewType; }
              if(row.qaPassFail !=''){  row.qAReviewType = row.qaPassFail; }
              if(row.waCurrent !=''){  row.wACurrent = row.waCurrent; }
              if(row.copReceivedDate !=''){  row.cOPReceivedDate = row.copReceivedDate; }
              if(row.copSubmittedDate !=''){  row.cOPSubmittedDate = row.copSubmittedDate; }
              if(row.w1S !=''){  row.w1s = row.w1S; }
              if(row.w2S !=''){  row.w2s = row.w2S; }
              if(row.w3S !=''){  row.w3s = row.w3S; }                    
              if( `${row.lmeComplete}`!=''){  row.lMEComplete = row.lmeComplete; } 
              row.updatedDate = new Date(row.updatedDate)

            } catch {
              console.warn('ERROR CONVERTING UPDATED DATE', row)
            }
            // try{
            //   if( `${row.waPricingStructure}`!=''){  row.wAPricingStructure = row.waPricingStructure; }
            //   if( `${row.qaFailCount}`!=''){  row.qAFailCount = row.qaFailCount; }
            //   if( `${row.qaInitialScore}`!=''){  row.qAInitialScore = row.qaInitialScore; }
            //   if( `${row.qaReceived}`!=''){  row.qAReceived = row.qaReceived; }
            //   if( `${row.qaScore}`!=''){  row.qAScore = row.qaScore; }
            //   if( `${row.qaStatus}`!=''){  row.qAStatus = row.qaStatus; }
            //   if( `${row.qaSubmitted}`!=''){  row.qASubmitted = row.qaSubmitted; }
            //   if( `${row.w1S}`!=''){  row.w1s = row.w1S; }
            //   if( `${row.w2S}`!=''){  row.w2s = row.w2S; }
            //   if( `${row.w3S}`!=''){  row.w3s = row.w3S; }                    
              
            // }catch{

             
            // }
            // try {
              
            //   row.updatedDate = new Date(row.updatedDate)

            // } catch {
            //   console.warn('ERROR CONVERTING UPDATED DATE', row)
            // }
          });
          return retval;
        })
      )
      .subscribe({
        next: async (rows: any) => {
          this._countDown = this._countDown - 1;

          this._percentage = ((this._packageCount - this._countDown) / this._packageCount) * 100
          this.percentage.next(this._percentage);
          console.log(`countdown: ${this._countDown}`);
          if (rows.length > 0) {
            //make sure to eliminate duplciates
            let filteredRows = [];
            filteredRows = rows.filter((newRow: any) => !this.existingIds.has(newRow.jobID));
            filteredRows.forEach((row: any) => this.existingIds.add(row.jobID));
            // filteredRows.forEach((row: any) => {
            //   let date = new Date(row.updatedDate);
            //   if (isNaN(date.getTime())) {
            //     // If date is invalid, set to current date and time
            //     row.updatedDate = new Date();
            //   }

            //   row.updatedDate = new Date(row.updatedDate);
            // })

            if (filteredRows.length > 0) {
              if (this._countDown > -1) {
                filteredRows = await this.clearRows(gridApi, filteredRows);
                await gridApi.applyTransactionAsync({ add: filteredRows }, this.callback);
                //then we update the local DB
                this.idb.insertBulk('jobs', filteredRows, async () => {
                  console.log('done inserting ' + filteredRows.length + ' rows');
                });
              } else {
                console.warn("For some reason we keep loading " + this._countDown)
              }
            }

          }
          if (this._countDown === 0) {
            /* THIS IS A HACK.  
            I HAVE NO IDEA WHY, BUT WE STILL GET DUPLICATES IN AGGRID, SO I RESET THE ENTIRE THING WHEN WE ARE DONE 
            AND THEN JUST RUN THE UPDATE LOADER TO SEE IF WE MISSED ANYTHING WHILE DOING THE UPDATE
            */

            // let jobs = await this.idb.list("jobs");
            // gridApi.setRowData(jobs);

            this.updateLoader(gridApi,true);
          }
        }
      })
  }

  async clearRows(gridApi, rows) {
    var filteredRecs = [];

    rows.forEach(row => {
      const node = gridApi.getRowNode(row.jobID);
      if (!node) {

        filteredRecs.push(row);
      } else {
        //console.log("DUPLICATE FOUND", row)
      }
    });
    return filteredRecs;
  }


  // async updatePcTrackerLoader(gridApi) {

  //   let ed: Date = new Date("1/1/2020");
  //   //let existing = excludeJobs.toString();
  //   var dbLastModDate = await this.idb.updateStatus('first')
  //   if (dbLastModDate.length > 0) ed = dbLastModDate[0]
  //   console.log('last record updated was on ' + ed)
  //   let d = new Date(ed).toLocaleDateString();
  //   let t = new Date(ed).toLocaleTimeString();
  //   let sqlExpireDate: string = d + ' ' + t;
  //   let lastUpdate = this.dataStore.jobMaster.date
  //   let lastRemoval = this.dataStore.jobMaster.date
  //   let thisRequestDate = new Date();
  //   var cmd = new Command();
  //   var additions = [];
  //   var updates = [];

    
  //     console.log('retrieving updated data')
  //     //cmd.procedure = "cmdJobsMasterUpdate";
  //     cmd.procedure = "cmdJobsMasterUpdateLimited";
  //     cmd.addParameter("ExpireDate", sqlExpireDate);
    

  //   this.db.command(cmd)
  //     .subscribe(retval => {
  //       console.log("SR Cmd Job list => ", retval);
  //       let updateRecs = retval;
  //       let db = this.dataStore.jobMaster.data;
  //       let recordUpdated = false;
  //       let recordAdded = false;
  //       updateRecs.forEach(row => {
  //         //debugger
  //         row.updatedDate = new Date(row.updatedDate)
  //         let myRow = db.filter(r => r.jobID === row.jobID)[0];
  //         if (!myRow) {
  //           //  console.log('adding new row that was not in the cached dataset!')
  //           // this.idb.insert('jobs', row);
  //           additions.push(row);
  //           recordAdded = true;
  //           let lu = new Date(row.lastUpdate);
  //           if (lu > lastUpdate) {
  //             lastUpdate = lu;
  //           }
  //           updates.push(row);
  //         } else {
  //           updates.push(row);
  //           // update existing rows
  //           for (var propt in row) {
  //             for (var localPropt in myRow) {
  //               if (propt === localPropt && myRow[localPropt] != row[propt]) {
  //                 //  console.log(`updating ${localPropt} from ${myRow[localPropt]} to ${row[propt]}`)
  //                 myRow[localPropt] = row[propt]
  //                 recordUpdated = true;
  //                 let lu = new Date(row.lastUpdate);
  //                 if (lu > lastUpdate) {
  //                   lastUpdate = lu;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       });

  //       this.dataStore.jobMaster.date = lastUpdate;
  //       this.dataStore.jobMaster.data = [...this.dataStore.jobMaster.data, ...additions];
  //       //debugger
  //       //gridApi.applyTransaction({  update: updates });

  //       this.addToIDB(additions, updates)
  //       //this.cleanDeletes(new Date(sqlExpireDate))
  //       gridApi.redrawRows();
  //       this.chatter.jobMasterIsUpdting = false;
  //     }, err => {
  //       console.log('oh damn, there was an error on the refreshdb')
  //     })
  // }

  // async addToIDB(additions, updates = []) {
  //   await this.idb.insertBulk('jobs', additions, () => { console.log('done inserting') });
  //   console.log('updating bulk', updates.length)
  //   await this.idb.updateBulk('jobs', updates, () => { console.log('done updating') });

  // }

  async updateLoader(gridApi, forceexpire = false) {

    let ed: Date = new Date("1/1/2020");
    if (forceexpire) {
      let d = new Date();
      ed = new Date(d.getFullYear(), d.getMonth(), d.getDate(),5);
    } else {


      console.log('updated date is being retrieved')
      var dbLastModDate = await this.idb.updateStatus('first')
      console.log('updated date found', dbLastModDate)
      if (dbLastModDate.length > 0) {
        ed = new Date(dbLastModDate[0]);
      } else {
        console.log('no updated date found')
        // this.bulkLoader(gridApi);
        return;
      }
    }
    let sqlExpireDate: string = ed.toLocaleDateString() + ' ' + ed.toLocaleTimeString();

    console.log('retrieving updated data starting ' + sqlExpireDate)

    let cmd = new Command();
    cmd.procedure = "cmdJobsMasterUpdateLimited";
    cmd.addParameter("ExpireDate", sqlExpireDate);
    this.db.command(cmd)
      .subscribe({
        next: async retval => {

          let toUpdate: Array<any> = [];
          if (gridApi) {

            //cycle through the result set and update rows that match.
            gridApi.forEachNode((node) => {
              try {
                if (node.data && retval) {
                  //if there is no data at all, then this will give an error.
                  let check = retval.filter(r => r.jobID == node.data.jobID)
                  if (check.length > 0) {
                    node.updateData(check[0]);  //update the grid
                    toUpdate.push(check[0]);    //add to update array for indexeddb
                  }
                  //remove the updated record from the result set
                  retval = retval.filter(r => r.jobID != node.data.jobID)
                }
              } catch (exception) {
                console.warn('Error filtering out old records from node.data', node.data, exception);
              }
            });

            //anything left gets added to the top
            gridApi.applyTransaction({ add: retval, addIndex: 0 });
          }
          //then we update the local DB
          await this.idb.insertBulk('jobs', retval, function () { console.log('done inserting') });
          await this.idb.updateBulk('jobs', toUpdate, function () { console.log('done updating') });
        }
      });
  }

}
