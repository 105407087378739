import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AgRendererComponent,  } from 'ag-grid-angular';
import { Command } from 'app/models/command';
import { DataStoreService } from 'app/services/datastore.service';
import { SiteReviewService } from 'app/services/site-review.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements AgRendererComponent, OnDestroy {

  @Input() cell: any;



  @Input()
  options: string[] = ["A", "B"];
  //options= [];
  @Input()
  selection: string[] = ["A"]

  @Input()
  id: number = 0;

  @Output("onChange")
  onChange: EventEmitter<any> = new EventEmitter();

  params;

  constructor(private db: SiteReviewService,
              private dataStore: DataStoreService) { }

  agInit(params: any): void {
   // console.log("ms params options => ", params.options)   
    //console.log("ms params => ", params)
    this.params = params;
    this.options = params.options.filter(opt=>opt.name!='')
    //this.getEventTypeOptions();
    if (!params.value) params.value = '';
    if (params.value.indexOf(",") > 0) {
      this.selection = params.value.split(',');
    } else {
      this.selection = [params.value];
    }


    // if (params.value != '') {
    //   this.selection.forEach(opt => {
    //     let checker = this.options.filter(option => opt == option);
    //     if (checker.length == 0) this.options.push(opt);
    //   })
    // }

    
  }

  getGui(params) {
    console.log("getGui => ", params)
  }

  afterGuiAttached(params) {
    console.log("afterGuiAttached => ", params)
  }

  ngOnDestroy() {
    //console.log("on destroy multi select component")
  }

  getEventTypeOptions(){
    let cmd = new Command();
    cmd.procedure = 'cmdGetJobScheduleTypeOptions';
    cmd.addParameter('JobID', this.params.data.jobID);
    this.db.command(cmd)
      .subscribe(retval => {
        //console.log('cmdGetJobScheduleTypeOptions ' + retval);
        this.options = retval;
      });
  }


  setValue(item: string[]) {
    // let value = 0

    // this.options.forEach(option => {
    //   if(option.eventType === this.selection.toString()) {
    //     value = option.jobScheduleID;
    //     console.log("option => ", option.eventType, option.jobScheduleID)
    //   }
    // })

    // this.fieldUpdate(this.params.table, this.params.fieldName, this.selection.toString(), this.params.key, this.params.data.jobID)

    let key = 0
    if(!this.params.data[this.params.key]){
      key= this.params.key[0].toLowerCase() + this.params.key.substr(1); 
    }else{
      key = this.params.key;
    }
      let id = this.params.data[key]
     
     
     this.params.value = this.selection.toString();
     console.log('updating multiselect => ', this.selection.toString());
     this.fieldUpdate(this.params.table, this.params.fieldName, this.selection.toString(), this.params.key, id);

return;




    /*  If you already have a civilJobScheduleID, then use it */
    // let civilJobScheduleID = this.params.data.civilJobScheduleID ?? 0;

    // console.log("civilJobScheduleID => ", civilJobScheduleID);

    // if(civilJobScheduleID>0){

    //   let cmd = new Command();
    //   cmd.procedure = "cmdFieldUpdate";
    //   cmd.addParameter("Table", 'JobSchedule');
    //   cmd.addParameter("Field", 'EventTypeID');
    //   cmd.addParameter("Value", value);
    //   cmd.addParameter("Key", "JobScheduleID");
    //   cmd.addParameter("ID", civilJobScheduleID);
    //   this.db.command(cmd).subscribe(retval => {
    //     console.log('Updated JobEventType ' , retval);
    //     this.dataStore.updateJobIndexDB(retval)
    //   });
    // } else{
/* 
If you don't already have an id for a civil job, 
then you need to get one  and THEN you can do your update 
*/      
    // let cmd1 = new Command();
    // cmd1.procedure = "cmdJobScheduleUpdate";
    // cmd1.addParameter("JobID", this.params.data.jobID);
    // cmd1.addParameter("EventTypeID", civilJobScheduleID);
    // this.db.command(cmd1).subscribe(retval => {
    //   console.log('cmdJobScheduleUpdate ', retval);

    //   let jobScheduleID = retval[0].jobScheduleID;
    //   //event.node.setDataValue('civilJobScheduleID', jobScheduleID);

    //   let cmd = new Command();
    //   cmd.procedure = "cmdFieldUpdate";
    //   cmd.addParameter("Table", 'JobSchedule');
    //   cmd.addParameter("Field", 'EventTypeID');
    //   cmd.addParameter("Value", value);
    //   cmd.addParameter("Key", "JobScheduleID");
    //   cmd.addParameter("ID", jobScheduleID);
    //   this.db.command(cmd).subscribe(retval => {
    //     console.log('Updated jobScheduleID ', retval);
    //     this.dataStore.updateJobIndexDB(retval)
    //   });
    // });
    // }

    // this.fieldUpdate(this.params.table, this.params.fieldName, this.selection.toString(), this.params.key, this.params.data.jobID)
  }


  // setValue(item: string[]) {

  //   console.log(item)
  //   let key = 0
  //   if(!this.params.data[this.params.key]){
  //     key= this.params.key[0].toLowerCase() + this.params.key.substr(1); 
  //   }else{
  //     key = this.params.key;
  //   }
  //     let id = this.params.data[key]
     
     
  //    this.params.value = this.selection.toString();
  //    console.log('updating multiselect => ', this.selection.toString(), this.params);
  //    this.fieldUpdate(this.params.table, this.params.fieldName, this.selection.toString(), this.params.key, id);
   
  // }

  fieldUpdate(table, fieldName, value, key, id) {
    var cmd = new Command();
    cmd.procedure = "cmdFieldUpdate";
    cmd.addParameter("Table", table);
    cmd.addParameter("Field", fieldName);
    cmd.addParameter("Value", value);
    cmd.addParameter("Key", key);
    cmd.addParameter("ID", id);
    this.db.command(cmd).subscribe(retval => {
      console.log('Updated ' + fieldName, retval);
     //  this will happen automatically with the background service
     //  this.dataStore.updateJobIndexDB(retval);
     // ----------------------------------------------------------
      this.saveRecentHistoryJobEdited(retval[0])
    })

  }

   jobScopeType(){
    let eventType = this.params.data.jobScope;
    if(eventType === '') eventType = 0

    let cmd1 = new Command();
    cmd1.procedure = "cmdJobScheduleUpdate";
    cmd1.addParameter("JobID", this.params.data.jobID);
    cmd1.addParameter("EventType", eventType);
    this.db.command(cmd1).subscribe(retval => {
      console.log('cmdJobScheduleUpdate ', retval);
    });
   }

  refresh(params: any): boolean {
    return true;
  }


  async saveRecentHistoryJobEdited(job){

   let recentEditJobs =  await localStorage.getItem('RecentJobsEdited');

    job.lastEditHistory = new Date().toLocaleString("PST");

    let recentJobArray = JSON.parse(recentEditJobs);
    if(recentJobArray != null){
      let findJobIndex = recentJobArray.findIndex(j => j.jobID === job.jobID);
      console.log("findJobIndex ", findJobIndex)
      if(findJobIndex != -1){
        recentJobArray[findJobIndex] = job;
      } else {
        //recentJobArray = [job, ...recentJobArray];
        recentJobArray.unshift(job)
      }
    } else {
      recentJobArray = [job]
    }
    let formattedRecentJobsHistory = JSON.stringify(recentJobArray)
    recentEditJobs = formattedRecentJobsHistory;
    localStorage.setItem('RecentJobsEdited', formattedRecentJobsHistory);

    //console.log("recentJobs stored => ", recentJobArray)
  }


}
