export const environment = {
 
  production: true,
  serverURL: 'https://pinnacleproduction.azurewebsites.net',
  siteReviewURL: 'https://pinnacle.apidas.com',     

  trashFolder: '18OJv1-wWz8Dw6ll_0c1dlthdEnEY3JFO',
  authenticationExpirationTimeoutMinutes: 36000,  //(10 hours - per tyler request)

  adobePDFkey:'cbac94b282ed433dafcaca3ce57b3da7',   // used for embedded pdf viewer

};

 

